<template>
    <div>

            <div class="about-info about-info-odd">
                <div class="container-lg">
                    <div class="about-info-inner">
                        <div class="row">
                            <div class="col-lg-6 order-2 order-lg-1">
                                <div class="about-info-txt">
                                    <h3 class="section-title">About Sabasi</h3>
                                    <p class="lead">Sabasi is a web & mobile application originally designed for use in resource-constrained environments with challenges such as unreliable connectivity or power infrastructure.</p>
                                    <p>It is a free tool which will help individuals and organizations author, field, and manage mobile data collection solutions.</p>
                                    <router-link to="/features">
                                        <button class="hero-btn hero-btn-secondary">Explore Features</button>
                                    </router-link>
                                </div>
                            </div>
                            <div class="col-lg-6 order-1 order-lg-2">
                                <div class="about-info-img">
                                    <img loading="lazy" src="@/assets/img/landing/laptop-in-workshop.jpg" alt="Macbook on a workshop table" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="about-info">
                <div class="container-lg">
                    <div class="about-info-inner">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="about-info-img">
                                    <img loading="lazy" src="@/assets/img/landing/gps-tracking.jpg" alt="Close up of hands holding a GPS device" />
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="about-info-txt">
                                    <h3 class="section-title">Background</h3>
                                    <p>Many African organisations and governments have undertaken research activities through rudimental means, most commonly, paper-based data collection. This method requires lengthy verification processes, not to mention potential data loss and loss of data integrity. Sabasi enables efficient, low-cost digital data collection and processing. This innovative tool is easy to use and requires minimal training.</p>
                                </div>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>    

            <div class="about-info about-info-odd">
                <div class="container-lg">
                    <div class="about-info-inner">
                        <div class="row">
                            <div class="col-lg-6 order-2 order-lg-1">
                                <div class="about-info-txt">
                                    <h3 class="section-title">Case study</h3>
                                    <p>Our drive to develop a high-impact data tool took us to Bujumbura where we immersed ourselves in training and knowledge sharing of Sabasi app implementation and improvements with Lake Tanganyika Floating Health Clinic (LTFHC) field workers. The field workers have powerful insights in survey exercises in the most remote areas of Congo and Burundi.</p>
                                    <a href="https://openinstitute.africa/data-collection-made-for-africa/" target="_blank" title="Read the full story on the Open Institute website" rel="nofollow noreferrer">
                                        <button class="hero-btn hero-btn-secondary">
                                            Read the Full Story
                                            <font-awesome-icon icon="external-link-alt" />
                                        </button>
                                    </a>
                                </div>
                            </div>
                            <div class="col-lg-6 order-1 order-lg-2">
                                <div class="about-info-img">
                                    <img loading="lazy" src="@/assets/img/landing/ltfhc-training-bujumbura.jpg" alt="Sabasi training for Lake Tanganyika Floating Health Clinic" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>                    

    </div>
</template>

<script>
export default {
    name: 'About',
    methods: {
        auth() {
            return localStorage.getItem(process.env.VUE_APP_NAME + "_token") != null;
        }
    }
}
</script>

<style scoped>
</style>